import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import type { JSX } from 'react';
import { memo } from 'react';
import type { ProgramsHighlightProps } from '../ProgramsHighlight';
import type { ProgramsHighlightContent } from '../types';
import styles from './ProgramsHighlightTv.module.css';

type ProgramsHighlightTvProps = {
  img: JSX.Element;
  Linker: ProgramsHighlightProps['Linker'];
  renderLinker: ProgramsHighlightProps['renderContentImageLinker'];
  content?: ProgramsHighlightContent;
  trackingContext?: ApiV2Context;
};

function ProgramsHighlightTvComponent({
  content,
  img,
  trackingContext,
  Linker,
  renderLinker,
}: ProgramsHighlightTvProps): JSX.Element {
  const { button, description, diffusionLabel, title, onClick } = content || {};
  const { onClick: mainOnClick } = button || {};

  return (
    <section
      className={classNames(
        styles.ProgramsHighlightTv,
        'programsHighlightFocus',
      )}
    >
      <div className={styles.mediaWrapper}>
        {onClick ? (
          renderLinker(
            onClick,
            img,
            'highlight_mediaWrap',
            styles.ProgramsHighlight__mediaWrap__linker,
            trackingContext,
          )
        ) : (
          <div
            className={styles.ProgramsHighlight__mediaWrap__linker}
            data-tv-focusable
          >
            {img}
          </div>
        )}
      </div>

      <Linker
        data={{ mainOnClick: { ...mainOnClick, trackingContext } }}
        objKey="onClick"
      >
        <div className={styles.ctaWrapper}>
          {diffusionLabel && (
            <h2 className={styles.ctaWrapper__diffusionLabel}>
              {diffusionLabel}
            </h2>
          )}
          {title && <span className={styles.ctaWrapper__title}>{title}</span>}
          {description && (
            <p className={styles.ctaWrapper__description}>{description}</p>
          )}
        </div>
      </Linker>
    </section>
  );
}

export const ProgramsHighlightTv = memo(ProgramsHighlightTvComponent);
