import type { ResizeMode } from '@canalplus/mycanal-commons';
import {
  ChannelsGrid,
  ImageWall,
  OfferHighlight as OfferHighlightLegacy,
  ProgramsHighlight,
  ReboundProduct,
  ShowcaseImage,
  Tiles,
} from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import type {
  ApiV2Context,
  ApiV2OnClick,
} from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import type { JSX, ReactNode } from 'react';
import { memo } from 'react';
import LoadableBanner from '../../../components/Banner/LoadableBanner';
import Linker from '../../../components/Linker/Linker';
import LoadablePlainTextHtmlTemplate from '../../../components/PlainTextHtml/LoadablePlainTextHtmlTemplate';
import ButtonLayer from '../../../components/Showcase/ButtonLayer/ButtonLayer';
import Separator from '../../../components/Showcase/Separator/Separator';
import TextLayer from '../../../components/Showcase/TextLayer/TextLayer';
import { ShowcaseTypes } from '../../../constants/showcase';
import { getLocationStateContext } from '../../../helpers/contents/contents-helper';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import type { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import {
  MIDDLEWARE_SHOWCASE,
  MIDDLEWARE_SHOWCASE_TOP,
} from '../../../helpers/oneNavigation/middleware';
import { useTranslation } from '../../../lang';
import {
  featDisplayHeaderMenuSelector,
  getFeatureToggleOfferHighlightVideo,
} from '../../../store/slices/application-selectors';
import LoadableContentRowStandard from '../../ContentRowStandard';
import { OfferHighlight } from './OfferHighlight/OfferHighlight';
import styles from './Showcase.css';

// Removes invalid strates from states array to prevent crashing
function filterInvalidStrates(strate: any) {
  return (
    strate &&
    (strate?.type !== ShowcaseTypes.OFFERHIGHLIGHT ||
      strate?.type !== ShowcaseTypes.BRANDDISCOVER ||
      strate?.contents?.length)
  );
}

export type ShowcaseProps = {
  areAnimationsAutoplay: boolean;
  strates?: any;
  isSmallScreenWidth: boolean;
  resizeMode?: ResizeMode;
  focusManager?: FocusManager;
};

// The Template has to match each strate to a component
function Showcase({
  areAnimationsAutoplay,
  isSmallScreenWidth,
  resizeMode,
  strates,
  focusManager,
}: ShowcaseProps): JSX.Element {
  const isFirstStrateOfferHighlight =
    strates &&
    (strates[0]?.type === ShowcaseTypes.OFFERHIGHLIGHT ||
      strates[0]?.type === ShowcaseTypes.BRANDDISCOVER);
  const featDisplayHeaderMenu = useInvariantSelector(
    featDisplayHeaderMenuSelector,
  );
  const isFeatOfferHighlightVideo = useInvariantSelector(
    getFeatureToggleOfferHighlightVideo,
  );

  const hasStrates = strates?.length;

  const { t } = useTranslation();

  const offerHighLightLabelPlayPauseBtn = {
    pauseBtn: t('ShowcaseTemplate.OfferHighlight.pauseBtn'),
    playBtn: t('ShowcaseTemplate.OfferHighlight.playBtn'),
  };

  const renderWrapperLinker = (
    onClick: ApiV2OnClick,
    children: ReactNode,
    id: string,
    className?: string,
    trackingContext?: ApiV2Context,
  ) => (
    <Linker
      className={className}
      data={{
        mainOnClick: { ...onClick, trackingContext },
        context: getLocationStateContext(onClick.displayTemplate),
      }}
      objKey="onClick"
      id={id}
    >
      {children}
    </Linker>
  );

  return (
    <div
      className={classNames(styles.showcase, {
        [styles['showcase--withHeader']!]:
          isFirstStrateOfferHighlight && featDisplayHeaderMenu,
      })}
    >
      {hasStrates && (
        <section className={classNames(styles.showcase__strates)}>
          {strates
            .filter(filterInvalidStrates)
            .map((strate: any, index: number) => {
              const isFirstStrate = index === 0;

              // We need a stable key for showcase strates because when there is more than
              // 10s between SSR fetch and client hydrate, react-query refetch showcase content.
              // In this case strate.hash is updated randomly, this causes an undesired
              // unmount / remount that causes a lost of focus
              const hash = $_BUILD_RENDERMODE_CSR ? index : strate?.hash;

              // Assign a specific middleware based on the index value.
              // Items with an index less than or equal to 2 are assumed to be in the viewport
              // and require a "scroll to top" middleware (MIDDLEWARE_SHOWCASE_TOP).
              // All other items use the default middleware (MIDDLEWARE_SHOWCASE).
              const middleware =
                index <= 2 ? MIDDLEWARE_SHOWCASE_TOP : MIDDLEWARE_SHOWCASE;

              switch (strate?.type) {
                case ShowcaseTypes.TEXT:
                  return <TextLayer contents={strate.contents} key={hash} />;

                case ShowcaseTypes.BUTTON:
                  return (
                    <Binder middleware={middleware} key={hash}>
                      <ButtonLayer
                        contents={strate.contents}
                        id={strate.hash}
                      />
                    </Binder>
                  );

                case ShowcaseTypes.CHANNELSGRID:
                  return (
                    <ChannelsGrid
                      contents={strate.contents}
                      description={strate.description}
                      key={hash}
                      title={strate.title}
                      isTvDevice={$_BUILD_RENDERMODE_CSR}
                      resizeMode={resizeMode}
                    />
                  );

                case ShowcaseTypes.OFFERHIGHLIGHT:
                case ShowcaseTypes.BRANDDISCOVER:
                  if (!strate.contents.length) {
                    return null;
                  }
                  return (
                    <Binder
                      middleware={middleware}
                      enabled={
                        strate?.contents.length > 0 &&
                        !!strate.contents[0]?.buttons
                      }
                      key={hash}
                    >
                      {isFeatOfferHighlightVideo ? (
                        <OfferHighlight
                          contents={strate?.contents}
                          isSmallScreenWidth={isSmallScreenWidth}
                          isVideoEnabled={areAnimationsAutoplay}
                          trackingContext={strate.context}
                          focusManager={
                            isFirstStrate ? focusManager : undefined
                          }
                        />
                      ) : (
                        <OfferHighlightLegacy
                          contents={strate.contents}
                          isSmallScreenWidth={isSmallScreenWidth}
                          isTvDevice={$_BUILD_RENDERMODE_CSR}
                          isVideoDisabled={!areAnimationsAutoplay}
                          resizeMode={resizeMode}
                          key={hash}
                          trackingContext={strate.context}
                          labelPlayPauseBtn={offerHighLightLabelPlayPauseBtn}
                          Linker={Linker}
                        />
                      )}
                    </Binder>
                  );

                case ShowcaseTypes.PROGRAMSHIGHLIGHT:
                  return (
                    <Binder
                      middleware={middleware}
                      key={hash}
                      enabled={strate.contents[0]}
                    >
                      <ProgramsHighlight
                        content={strate.contents[0]}
                        isTvDevice={$_BUILD_RENDERMODE_CSR}
                        resizeMode={resizeMode}
                        Linker={Linker}
                        trackingContext={strate.context}
                        renderContentImageLinker={renderWrapperLinker}
                      />
                    </Binder>
                  );

                case ShowcaseTypes.TILES:
                  return (
                    <Binder key={hash} middleware={middleware}>
                      <Tiles
                        contents={strate.contents}
                        isTvDevice={$_BUILD_RENDERMODE_CSR}
                        resizeMode={resizeMode}
                        Linker={Linker}
                        title={strate.title}
                      />
                    </Binder>
                  );

                case ShowcaseTypes.IMAGE:
                  return (
                    <Binder key={hash} middleware={middleware}>
                      <ShowcaseImage
                        button={strate.buttons?.[0]}
                        content={strate.contents[0]}
                        description={strate.description}
                        focusStyle={styles.showcase__focusStyle}
                        isTvDevice={$_BUILD_RENDERMODE_CSR}
                        resizeMode={resizeMode}
                        Linker={Linker}
                        title={strate.title}
                      />
                    </Binder>
                  );

                case ShowcaseTypes.SEPARATOR:
                  return <Separator key={hash} />;

                case ShowcaseTypes.CONTENTROW: {
                  return strate.contents ? (
                    <LoadableContentRowStandard
                      contents={strate.contents}
                      displayParameters={strate?.displayParameters}
                      header={{
                        title: strate?.title,
                        subtitle: strate?.subtitle,
                        button: strate?.button,
                      }}
                      key={hash}
                      URLNextPage={strate?.paging?.URLPage}
                      middleware={middleware}
                      focusManager={focusManager}
                    />
                  ) : null;
                }
                case ShowcaseTypes.PLAINTEXTHTML:
                  return (
                    <LoadablePlainTextHtmlTemplate
                      title={strate.title}
                      html={strate.html}
                      style={strate.css}
                      script={strate.js}
                      key={hash}
                    />
                  );

                case ShowcaseTypes.REBOUNDPRODUCT:
                  return (
                    <Binder
                      key={hash}
                      middleware={middleware}
                      enabled={!!strate.button}
                    >
                      <ReboundProduct
                        button={strate.button}
                        title={strate.contents[0]?.title}
                        urlLogo={strate.contents[0]?.URLLogo}
                        altLogo={strate.contents[0]?.altLogo}
                        trackingContext={strate.context}
                        Linker={Linker}
                      />
                    </Binder>
                  );

                case ShowcaseTypes.BANNER:
                  return (
                    <Binder middleware={middleware} key={hash}>
                      <LoadableBanner
                        contents={strate.contents}
                        resizeMode={resizeMode}
                      />
                    </Binder>
                  );

                case ShowcaseTypes.WALL:
                  return (
                    <Binder key={hash} middleware={middleware}>
                      <ImageWall
                        button={strate.button}
                        contents={strate.contents}
                        description={strate.description}
                        title={strate.title}
                        subtitle={strate.subtitle}
                        isTvDevice={$_BUILD_RENDERMODE_CSR}
                        resizeMode={resizeMode}
                        renderContentCardLinker={renderWrapperLinker}
                        titleDisplayMode={
                          strate?.displayParameters?.titleDisplayMode
                        }
                        Linker={Linker}
                        trackingContext={strate.context}
                      />
                    </Binder>
                  );

                default:
                  return null;
              }
            })}
        </section>
      )}
    </div>
  );
}

export default memo(Showcase);
