import { Ratio, getRatio, type ResizeMode } from '@canalplus/mycanal-commons';
import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import type {
  ApiV2Context,
  ApiV2StrateContentButton,
} from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2PageContentWall } from '@dce-front/hodor-types/api/v2/page/dtos/strates/wall/definitions';
import classNames from 'classnames';
import type { JSX, ReactNode } from 'react';
import { memo } from 'react';
import { getRatioClassname } from '../../helpers/getRatioClassname';
import styles from './ImageWall.module.css';
import { ImageWallContent } from './ImageWallContent/ImageWallContent';
import { ImageWallCta } from './ImageWallCta/ImageWallCta';
import ImageWallTv from './ImageWallTv/ImageWallTv';
import { formatImageWallContents } from './helpers';
import type { RenderCardLinkerType } from './type';

export type ImageWallProps = {
  Linker: (props: any) => ReactNode;
  renderContentCardLinker: RenderCardLinkerType;
  button?: ApiV2StrateContentButton;
  contents?: ApiV2PageContentWall[];
  description?: string;
  isTvDevice?: boolean;
  resizeMode?: ResizeMode;
  subtitle?: string;
  title?: string;
  titleDisplayMode?: TitleDisplayMode;
  trackingContext?: ApiV2Context;
};

/**
 * @param button                  CTA button object
 * @param contents                Contents to display into ImageWallCard
 * @param description             CTA description
 * @param isTvDevice              application launched from tv
 * @param renderContentCardLinker Render prop to allow MyCanal (and other libs) to wrap wall card contents in their own Linker
 * @param subtitle                CTA subtitle
 * @param title                   CTA title
 * @param titleDisplayMode        Handle title display like : "none" or "titleOnly"
 * @param trackingContext         Tracking context object
 */
function ImageWallComponents({
  button,
  contents = [],
  description,
  isTvDevice,
  Linker,
  renderContentCardLinker,
  resizeMode,
  subtitle,
  title,
  titleDisplayMode = TitleDisplayMode.TitleOnly,
  trackingContext,
}: ImageWallProps): JSX.Element {
  const formattedContents: ApiV2PageContentWall[] =
    formatImageWallContents(contents);
  const ctaContents = (
    <ImageWallCta
      button={button}
      description={description}
      isTvDevice={isTvDevice}
      Linker={Linker}
      subtitle={subtitle}
      title={title}
      trackingContext={trackingContext}
    />
  );

  const cardCounter34 = { count: 0 };
  const cardCounter169 = { count: 0 };

  // We do not want to have the last hidden element on TV
  if (isTvDevice) {
    formattedContents.pop();
    return (
      <ImageWallTv
        contents={contents}
        textElement={ctaContents}
        trackingContext={trackingContext}
        cardRender={renderContentCardLinker}
        resizeMode={resizeMode}
      />
    );
  }

  const shouldDisplayTitle = [
    TitleDisplayMode.None,
    TitleDisplayMode.LogoTyped,
  ].every((displayMode) => displayMode !== titleDisplayMode);

  return (
    <div className={classNames(styles.ImageWall, 'imageWallFocus')}>
      {/* CTA JUMBO (hidden after tablet) */}
      {!isTvDevice && (
        <div className={styles.ImageWall__ctaJumboWrap}>{ctaContents}</div>
      )}

      {/* WALL GRID */}
      <div className={styles.ImageWall__grid}>
        {/* CTA CARD (hidden for phone and tablet) */}
        <div
          className={classNames(
            styles.ImageWall__grid__item,
            getRatioClassname(Ratio.Ratio34),
            styles['ImageWall__grid__item--cta'],
          )}
          data-testid="imageWall-grid-item"
          key="cta-card"
        >
          <div className={styles.ImageWall__grid__item__contentWrap}>
            {ctaContents}
          </div>
        </div>

        {/* CONTENT CARDS */}
        {formattedContents.map((content, index) => {
          const {
            altImage,
            altLogoChannel,
            contentID,
            onClick,
            URLImage,
            imageRatio,
            URLLogoChannel,
          } = content;
          const ratio = getRatio({ imageRatio });
          const cardCounter =
            imageRatio === Ratio.Ratio34 ? cardCounter34 : cardCounter169;
          cardCounter.count += 1;

          const contentComponent = (
            <ImageWallContent
              imageAlt={altImage}
              imageUrl={URLImage}
              isTvDevice={isTvDevice}
              logoAlt={altLogoChannel}
              logoUrl={URLLogoChannel}
              ratio={ratio}
              {...(shouldDisplayTitle && { title: content.title })}
            />
          );

          return (
            <div
              className={classNames(
                styles.ImageWall__grid__item,
                getRatioClassname(ratio),
                styles[
                  `ImageWall__grid__item--ratio${imageRatio}-${cardCounter.count}`
                ] /* handles grid position */,
              )}
              data-testid="imageWall-grid-item"
              key={`${contentID}-${imageRatio}-${content.title}`}
            >
              <div
                className={styles.ImageWall__grid__item__contentWrap}
                tabIndex={0}
                role="link"
              >
                {onClick ? (
                  renderContentCardLinker(
                    onClick,
                    contentComponent,
                    `${contentID}_${index}`,
                    styles.ImageWall__grid__item__contentWrap__linker,
                    { ...trackingContext, context_list_position: index + 1 },
                  )
                ) : (
                  <div className={styles.ImageWall__grid__item__contentWrap}>
                    {contentComponent}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export const ImageWall = memo(ImageWallComponents);
