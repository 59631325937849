import { useEffect, useState } from 'react';
import { useIntersectionObserver } from '../../../helpers/hooks/useIntersectionObserver';
import { useVideoState } from '../context/hooks/useVideoState';

export function useIntersectionObserverForVideo(intersectionThreshold = 0.3): {
  intersectionRef: (node: Element | null) => void;
  isRootRefIntersecting: boolean | undefined;
} {
  const [isIntersectionObserverReady, setIsIntersectionObserverReady] =
    useState(false);
  const { isFullFrame } = useVideoState();

  const { refCallback: intersectionRef, entries } = useIntersectionObserver({
    threshold: intersectionThreshold,
    isEnabled: isIntersectionObserverReady && !isFullFrame,
  });
  const isRootRefIntersecting =
    entries &&
    entries[0] &&
    entries[0]?.intersectionRatio > intersectionThreshold; // If intersectionRatio is 0.3, at least 30% (0.3) of the rootRef has to be in the viewport

  // Because the IntersectionObserver entry can have all values set to default
  // e.g. when a detail is opened after a page change
  useEffect(() => {
    if (!isRootRefIntersecting) {
      const timer = setTimeout(() => {
        setIsIntersectionObserverReady(true);
      }, 0);
      return () => clearTimeout(timer);
    }
    return;
  }, [isRootRefIntersecting]);

  return { intersectionRef, isRootRefIntersecting };
}
