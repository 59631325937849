import { DIMENSIONS } from '@canalplus/dive-utils';
import { type ResizeMode } from '@canalplus/mycanal-commons';
import type { ApiV2StrateContentButton } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import type { JSX, ReactNode } from 'react';
import { useMemo } from 'react';
import { ImageType } from '../ImageType/ImageType';
import styles from './ShowcaseImage.module.css';

export type ShowcaseImageContent = {
  type: string;
  URLImage: string;
  altImage: string;
};

export type ShowcaseImageProps = {
  content: ShowcaseImageContent;
  Linker: (props: any) => ReactNode;
  altImage?: string;
  button?: ApiV2StrateContentButton;
  description?: string;
  focusStyle?: string;
  isTvDevice?: boolean;
  resizeMode?: ResizeMode;
  title?: string;
};

export function ShowcaseImage({
  button,
  content,
  description,
  focusStyle,
  isTvDevice,
  resizeMode,
  Linker,
  title,
  altImage,
}: ShowcaseImageProps): JSX.Element {
  const URLImage = useMemo(() => ({ default: content.URLImage }), [content]);

  const image = useMemo(() => {
    const img = (
      <div
        className={classNames(
          styles.ShowcaseImage__imageWrap,
          'global-ShowcaseImage__imageWrap',
        )}
      >
        <ImageType
          dimensions={DIMENSIONS.SHOWCASE_IMAGE}
          URLImage={URLImage}
          loading="eager"
          isTvDevice={isTvDevice}
          resizeMode={resizeMode}
          altImage={altImage}
        />
      </div>
    );
    return button ? (
      <Linker
        data={{ mainOnClick: button.onClick }}
        objKey="onClick"
        title={title}
      >
        {img}
      </Linker>
    ) : (
      <div data-tv-focusable>{img}</div>
    );
  }, [URLImage, button]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classNames(styles.ShowcaseImage, focusStyle)}>
      {title && <div className={styles.ShowcaseImage__title}>{title}</div>}
      {description && (
        <p className={styles.ShowcaseImage__description}>{description}</p>
      )}
      {image}
    </div>
  );
}
