import classNames from 'classnames';
import type { JSX } from 'react';
import { MaterialChevronRightSvg } from '../../icons/icons';
import { DiLink } from '../internal/DiLink/DiLink';
import { linkCVA, linkIconCVA } from './Link.cva';
import type { LinkProps } from './Link.types';

/**
 * A Link component that renders an anchor tag with predefined styles.
 *
 * It supports hover, active, and disabled states for better user interaction.
 *
 * @example
 *
 * ```tsx
 * <Link href="https://example.com">Visit Example</Link>
 * ```
 */
export function Link({
  children,
  size = 'large',
  hasIcon = false,
  disabled = false,
  isReversed = false,
  iconPosition = 'right',
  className,
  ...props
}: LinkProps): JSX.Element {
  return (
    <DiLink
      className={classNames(
        linkCVA({
          isReversed,
          disabled,
          hasIcon,
          iconPosition,
          size,
        }),
        className
      )}
      icon={
        hasIcon ? (
          <MaterialChevronRightSvg
            aria-hidden="true"
            className={linkIconCVA({
              disabled,
              isReversed,
              size,
            })}
          />
        ) : undefined
      }
      disabled={disabled}
      {...props}
    >
      {children}
    </DiLink>
  );
}
