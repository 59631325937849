import classNames from 'classnames';
import type { JSX } from 'react';
import { memo } from 'react';
import styles from './Poster.module.css';

export type PosterPlaceholderProps = {
  className?: string;
  loader?: boolean;
  svg?: any;
};

/**
 * PosterPlaceholder
 * --------------------
 *
 * Used to render a placeholder when `Poster` has no content
 *
 * @param className  Custom `className` to add
 * @param loader     Whether loading mode is activated or not
 * @param svg        A Svg to render
 */
function PosterPlaceholderComponent({
  className = '',
  loader = true,
  svg = null,
}: PosterPlaceholderProps): JSX.Element {
  return (
    <div
      className={classNames(styles.poster, {
        [styles['poster--loader']]: loader,
        [className]: className,
      })}
      data-testid="poster-placeholder"
    >
      {svg}
    </div>
  );
}

export const PosterPlaceholder = memo(PosterPlaceholderComponent);
