import type { HashObjectReturn } from '@canalplus/mycanal-util-react';
import type { JSX } from 'react';
import { memo } from 'react';
import { TextLayerTypes } from '../../../constants/showcase';
import type { CatchPhraseResources } from '../CatchPhrase/CatchPhrase';
import CatchPhrase from '../CatchPhrase/CatchPhrase';
import ShowcaseText from '../ShowcaseText/ShowcaseText';

export type TextLayerContent = {
  resources: CatchPhraseResources[];
  text: string[];
  type: TextLayerTypes;
};

export type TextLayerProps = {
  contents: HashObjectReturn<TextLayerContent>[];
};

function TextLayer({ contents }: TextLayerProps): JSX.Element {
  return (
    <>
      {contents.map((content) => {
        switch (content.type) {
          case TextLayerTypes.TITLE:
            return <CatchPhrase content={content} key={content.hash} />;
          case TextLayerTypes.SUBTITLE:
          case TextLayerTypes.PARAGRAPH:
          case TextLayerTypes.TITLE2:
            return (
              <ShowcaseText
                content={content}
                type={content.type}
                key={content.hash}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
}

export default memo(TextLayer);
